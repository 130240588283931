import { useApolloClient, useLazyQuery } from '@apollo/client'
import { notification } from '@faceup/ui-base'
import { useCallback, useEffect, useRef } from 'react'
import { sharedMessages } from '../Shared/translations'
import { useIntl } from '../TypedIntl'
import { graphql } from '../__generated__'
import useStorage, { StorageKeys } from './useStorage'

const query = {
  ReportPasswordDiscovery: graphql(`
    query ReportPasswordDiscoveryQuery($password: GraphQLString!) {
      reportPasswordDiscovery(password: $password) {
        regionId
        institutionId
        reportSourceId
        type
      }
    }
  `),
  ShortlinkDiscovery: graphql(`
    query ShortlinkDiscoveryQuery($shortlink: GraphQLString!) {
      shortlinkDiscovery(shortlink: $shortlink) {
        regionId
        institutionId
        reportSourceId
        type
      }
    }
  `),
  TokenDiscovery: graphql(`
    query TokenDiscoveryQuery($token: GraphQLString!) {
      region: tokenDiscovery(token: $token)
    }
  `),
}

const useRegion = () => {
  const client = useApolloClient()
  const { get, set } = useStorage()
  const { formatMessage } = useIntl()
  const region = useRef<string | null>(null)

  const resetRegion = useCallback(
    (currentRegion: string | null, previousRegion: string | null) => {
      if (currentRegion && currentRegion !== previousRegion) {
        set(StorageKeys.Region, currentRegion)

        client.stop()
        client.resetStore()
      }
    },
    [client, set]
  )

  const [fetchByReportPassword] = useLazyQuery(query.ReportPasswordDiscovery, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const [fetchByShortlink] = useLazyQuery(query.ShortlinkDiscovery, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const [fetchTokenDiscovery] = useLazyQuery(query.TokenDiscovery, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  useEffect(() => {
    const storedRegion = get(StorageKeys.Region)

    if (storedRegion) {
      region.current = storedRegion
    }
  }, [get])

  useEffect(() => {
    const storedRegion = get(StorageKeys.Region)

    resetRegion(region.current, storedRegion)
  }, [get, resetRegion])

  const discoverByReportPassword = useCallback(
    async (password: string) => {
      /* if (region.current) {
      return null
    } */

      const { data } = await fetchByReportPassword({
        variables: { password },
      })

      if (!data?.reportPasswordDiscovery) {
        return null
      }

      const prevRegion = region.current
      region.current = data.reportPasswordDiscovery.regionId

      resetRegion(region.current, prevRegion)

      return {
        reportSourceId: data.reportPasswordDiscovery.reportSourceId,
        institutionId: data.reportPasswordDiscovery.institutionId,
        type: data.reportPasswordDiscovery.type,
      }
    },
    [fetchByReportPassword, resetRegion]
  )

  const discoverByShortlink = useCallback(
    async (shortlink: string) => {
      /* if (region.current) {
      return null
    } */

      const { data } = await fetchByShortlink({
        variables: { shortlink },
      })

      if (!data?.shortlinkDiscovery) {
        return null
      }

      const prevRegion = region.current
      region.current = data.shortlinkDiscovery.regionId

      resetRegion(region.current, prevRegion)

      return {
        reportSourceId: data.shortlinkDiscovery.reportSourceId,
        institutionId: data.shortlinkDiscovery.institutionId,
        type: data.shortlinkDiscovery.type,
      }
    },
    [fetchByShortlink, resetRegion]
  )

  const discoverByToken = useCallback(
    async (token: string) => {
      /* if (region.current) {
      return null
    } */

      const { data } = await fetchTokenDiscovery({
        variables: { token },
      })

      if (!data?.region) {
        return null
      }

      const prevRegion = region.current
      region.current = data.region

      resetRegion(region.current, prevRegion)

      return data.region
    },
    [fetchTokenDiscovery, resetRegion]
  )

  return {
    region,
    discoverByReportPassword,
    discoverByShortlink,
    discoverByToken,
  }
}

export default useRegion
